import React, { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../services/firebase.config';
import { collection, getDocs, or, orderBy, query, where } from 'firebase/firestore';
import FirebaseDateComponent from './FirebaseDateComponent';


function Past({isTeacher}) {
    const [user] = useAuthState(auth);
    const [search, setSearch] = useState(user.email);
    const [lessons, setLessons] = useState([]);

    
    const browse = async() => {
        const q = query(collection(db, "past"), or(where("client", "==", search), where("teacher", "==", search)), orderBy("date", "desc"))
        console.log("past");
        const snap = await getDocs(q);
        let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
        setLessons(data);
    }

    return (
        <>
            <button type="button" className="skola-button" data-bs-toggle="modal" data-bs-target="#past" onClick={browse}>
                Vēsture
            </button>
            <div className="modal fade" id="past" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: "transparent"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{background: "transparent"}}>
                    <div className="modal-content skola-popup">
                        <div className="modal-header" style={{background: "transparent"}}>
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Vēsture</h1>
                            <button id="close-history" type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}}>x</button>
                        </div>
                        <div className="modal-body" style={{background: "transparent"}}>
                            {isTeacher &&
                                <>
                                    <input onChange={(e)=>setSearch(e.target.value)}  value={search} type='text' className='form-control skola-form' placeholder='E-pasts...'/><br />
                                    <button className='form-control skola-button' onClick={browse}>Meklēt</button>
                                </>
                            }
                            <div className="skola-cards">
                                {lessons && lessons.map(({client,cname,date,desc,report,teacher,tname,type,id})=>(
                                    <div id={id} key={id}>
                                        <h5><FirebaseDateComponent timestamp={date} /> - {type}</h5>
                                        <b>Datums:</b> {date.toDate().toLocaleDateString('lv-LV')} <br />
                                        <b>Skolotājs:</b> {tname} ({teacher}) <br />
                                        <b>Skolēns:</b> {cname} ({client}) <br />
                                        <b>Tēma:</b> {desc} <br/>
                                        <b>Atskaite:</b> {report} <br/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Past