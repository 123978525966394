import React from 'react'
import { auth, db } from '../services/firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState, useEffect } from 'react';
import { query, collection, getDocs, orderBy } from 'firebase/firestore';
import FirebaseDateComponent from './FirebaseDateComponent';
import { signOut } from 'firebase/auth';
import Complete from './Complete';
import Past from './Past';
import ResetTickets from './ResetTickets';
import GiveTicket from './GiveTicket';

function Admin({person}) {
    const [user] = useAuthState(auth);
    const [lessons, setLessons] = useState([]);
    const [change, setChange] = useState(0);

    useEffect(()=>{
        const getData = async () => {
            let q = query(collection(db, "lessons"), orderBy("date", "asc"))
            console.log("admin");
            let snap = await getDocs(q);
            let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setLessons(data);
        }
        getData();
    }, [user, change])
    
    return (
        <div className="container text-center mt-5">
            <h1>Administrātora panelis</h1>
            <p>Pievienojies kā: {person.id}</p>
            <div className='skola-panel'>
                <h3>Nodarbības:</h3>
                <div className="skola-cards">
                    {lessons && lessons.map(({client,cname,created,date,desc,link,teacher,tname,type,id})=>(
                        <div id={id} key={id}>
                            <h5><FirebaseDateComponent timestamp={date} /> - {type}</h5>
                            <b>Datums:</b> {date.toDate().toLocaleDateString('lv-LV')} <br />
                            <b>Skolēns:</b> {cname} ({client}) <br />
                            <b>Tēma:</b> {desc} <br/>
                            <b>Links:</b> <a href={link}>{link}</a> <br />
                            <Complete change={change} setChange={setChange} uid={id} info={{
                                client, cname, teacher, tname, date, type, desc
                            }}/>
                        </div>
                    ))}
                </div>
                <span style={{marginRight: "10px"}}><Past isTeacher={true}/></span>
                <span style={{marginRight: "10px"}}><GiveTicket/></span>
                <span style={{marginRight: "10px"}}><ResetTickets/></span>
            </div>
            <div className='mb-5'><button className="skola-button" onClick={()=>signOut(auth)}>Log out</button></div>
        </div>
    )
}

export default Admin

    // create a button to reset all the limits and tickets
    // see the student list
    // see the teacher list
    // add a student
    // add a teacher
    // give a ticket to a student