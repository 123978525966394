import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../services/firebase.config';
import { addDoc, collection, getDocs, orderBy, query } from 'firebase/firestore';


function GiveTicket() {
    const [user] = useAuthState(auth);
    const [students, setStudents] = useState([]);
    const [selected, setSelected] = useState({cname: "Skolēns...", email: "E-pasts..."})
    const [success, setSuccess] = useState(false);
    const [type, setType] = useState("");

    useEffect(()=>{
        const getData = async ()=>{
            const q = query(collection(db, "students"), orderBy("name", "asc"));
            const snap = await getDocs(q);
            let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setStudents(data);
        }
        getData();
    }, [user])

    const create = async()=>{
        if(type==="") return;
        
        await addDoc(collection(db, 'tickets'), {
            email: selected.email,
            type: type,
        });

        document.getElementById("close").click();
    }

    return (
        <>
            <button type="button" className="skola-button" style={{marginTop: "10px"}} data-bs-toggle="modal" data-bs-target="#create-lesson">
                Iedot biļeti
            </button>
            <div className="modal fade" id="create-lesson" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content skola-popup">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Iedot biļeti</h1>
                            <button id="close" type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}} onClick={()=>setSuccess(false)}>x</button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="dropdown">
                                    <button className="skola-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selected.cname} ({selected.email})
                                    </button>
                                    <ul className="dropdown-menu">
                                        {students && students.map(({long, name, short, id})=>(
                                            <button className="dropdown-item" key={id} onClick={()=>setSelected({cname: name, email: id})}>{name} ({id})</button>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <br />
                            <p><b>Tips</b></p>
                            {selected.cname !== "Skolēns..." ? (
                                <div>
                                    <select className='form-control skola-form' value={type} onChange={(e)=>setType(e.target.value)}>
                                        <option>Izvēlies ilgumu...</option>
                                        <option>30 min</option>
                                        <option>60 min</option>
                                    </select><br />
                                    <button className='form-control skola-button' onClick={create}>Izveidot</button><br />
                                    {success && <div style={{textAlign: "center", color: "green"}}>Nodarbība izveidota veiksmīgi!</div>}
                                </div>
                            ):(
                                <>Izvēlies skolēnu...</>
                            )}
                        </div>
                    </div>
                </div>
        </div>
    </>
    )
}

export default GiveTicket
