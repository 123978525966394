import { doc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { db } from '../services/firebase.config'


function AddTopic({uid, change, setChange}) {
    const [topic, setTopic] = useState("");
    
    const add = async (newid) => {
        console.log("Add topic");
        await updateDoc(doc(db, "lessons", newid), {desc: topic})
        setChange(change + 1);
        setTopic("");
        document.getElementById("close-add").click();
    }

    return (
        <>
            <div className="modal fade" id="add" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: "transparent"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{background: "transparent"}}>
                    <div className="modal-content skola-popup">
                        <div className="modal-header" style={{background: "transparent"}}>
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Par ko vēlēsies runāt nodarbībā?</h1>
                            <button id="close-add" type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}}>x</button>
                        </div>
                        <div className="modal-body" style={{background: "transparent"}}>
                            <input onChange={(e)=>setTopic(e.target.value)}  value={topic} type='text' className='form-control skola-form' placeholder='Es gribēšu runāt par...'/><br />
                            <button className='form-control skola-button' id={uid} onClick={(e)=>add(e.target.id)}>Nosūtīt</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTopic