import React from 'react'
import { auth, db } from '../services/firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState, useEffect } from 'react';
import { query, collection, where, getDocs, orderBy } from 'firebase/firestore';
import FirebaseDateComponent from './FirebaseDateComponent';
import { signOut } from 'firebase/auth';
import Complete from './Complete';
import Past from './Past';
import CreateLesson from './CreateLesson';
import EditTeacher from './EditTeacher';

function Teacher({person}) {
    const [user] = useAuthState(auth);
    const [lessons, setLessons] = useState([]);
    const [change, setChange] = useState(0);
    const [selected, setSelected] = useState(null);

    useEffect(()=>{
        const getData = async () => {
            let q = query(collection(db, "lessons"), where("teacher", "==", user.email), orderBy("date", "asc"))
            console.log("teacher");
            let snap = await getDocs(q);
            let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setLessons(data);
        }
        getData();
    }, [user, change])

    // salabot hujņu, kur, kad pabeidz stundu, tad pazūd tā stunda, kas ir pirmā sarakstā, nevis tā, uz kuras uzspieda
    // to var mēģināt salabot ar select - pogai ir onclick has uzliek uid uz pareizo uid
    
    return (
        <div className="container text-center mt-5">
            <h1>Skolotāja panelis</h1>
            <p>Pievienojies kā: {person.name} ({person.id})</p>
            <div><EditTeacher person={person}/></div>
            <div className='skola-panel'>
                <h3>Tavas nodarbības:</h3>
                <div className="skola-cards">
                    {lessons && lessons.map(({client,cname,created,date,desc,link,teacher,tname,type,id})=>(
                        <div id={id} key={id}>
                            <h5><FirebaseDateComponent timestamp={date} /> - {type}</h5>
                            <b>Datums:</b> {date.toDate().toLocaleDateString('lv-LV')} <br />
                            <b>Skolēns:</b> {cname} ({client}) <br />
                            <b>Tēma:</b> {desc} <br/>
                            <b>Links:</b> <a href={link}>{link}</a> <br />
                            <button type="button" onClick={(e)=>setSelected({client,cname,created,date,desc,link,teacher,tname,type,id})} className="skola-button-secondary" style={{marginTop: "10px"}} data-bs-toggle="modal" data-bs-target="#complete">
                                Nodarbība pabeigta
                            </button>
                        </div>
                    ))}
                </div>
                <span style={{marginRight: "10px"}}><Past isTeacher={true}/></span> 
                <CreateLesson person={person} change={change} setChange={setChange}/>
                <Complete change={change} setChange={setChange} o={selected}/>
            </div>
            <div className='mb-5'><button className="skola-button" onClick={()=>signOut(auth)}>Log out</button></div>
        </div>
    )
}

export default Teacher
