import { doc, setDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { db } from '../services/firebase.config'


function EditTeacher({person}) {
    const [tname, setTname] = useState(person.name);
    const [desc, setDesc] = useState(person.desc);
    const [link, setLink] = useState(person.link);
    const [monday, setMonday] = useState(person.monday);
    const [tuesday, setTuesday] = useState(person.tuesday);
    const [wednesday, setWednesday] = useState(person.wednesday);
    const [thursday, setThursday] = useState(person.thursday);
    const [friday, setFriday] = useState(person.friday);
    const [saturday, setSaturday] = useState(person.saturday);
    const [sunday, setSunday] = useState(person.sunday);
    
    const edit = async () => {
        console.log("Edit teacher");
        await setDoc(doc(db, "teachers", person.id), {
            name: tname,
            desc: desc,
            link: link,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
        })
        document.getElementById("close-teacher").click();
    }

    return (
        <>
            <button type="button" className="skola-button" style={{marginTop: "10px"}} data-bs-toggle="modal" data-bs-target="#edit">
                Rediģēt savu info un grafiku
            </button>
            <div className="modal fade" id="edit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: "transparent"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{background: "transparent"}}>
                    <div className="modal-content skola-popup">
                        <div className="modal-header" style={{background: "transparent"}}>
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Rediģēt</h1>
                            <button id="close-teacher" type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}}>x</button>
                        </div>
                        <div className="modal-body" style={{background: "transparent"}}>
                            Vārds<input onChange={(e)=>setTname(e.target.value)}  value={tname} type='text' className='form-control skola-form' placeholder='Ernests...'/><br />
                            Bio<input onChange={(e)=>setDesc(e.target.value)}  value={desc} type='text' className='form-control skola-form' placeholder='Es esmu...'/><br />
                            Google Meet links<input onChange={(e)=>setLink(e.target.value)}  value={link} type='text' className='form-control skola-form' placeholder='https://...'/><br />
                            Pirmdiena<input onChange={(e)=>setMonday(e.target.value)}  value={monday} type='text' className='form-control skola-form' placeholder='17:00-21:00...'/><br />
                            Otrdiena<input onChange={(e)=>setTuesday(e.target.value)}  value={tuesday} type='text' className='form-control skola-form' placeholder='17:00-21:00...'/><br />
                            Trešdiena<input onChange={(e)=>setWednesday(e.target.value)}  value={wednesday} type='text' className='form-control skola-form' placeholder='17:00-21:00...'/><br />
                            Ceturdiena<input onChange={(e)=>setThursday(e.target.value)}  value={thursday} type='text' className='form-control skola-form' placeholder='17:00-21:00...'/><br />
                            Piektdiena<input onChange={(e)=>setFriday(e.target.value)}  value={friday} type='text' className='form-control skola-form' placeholder='17:00-21:00...'/><br />
                            Sestdiena<input onChange={(e)=>setSaturday(e.target.value)}  value={saturday} type='text' className='form-control skola-form' placeholder='17:00-21:00...'/><br />
                            Svētdiena<input onChange={(e)=>setSunday(e.target.value)}  value={sunday} type='text' className='form-control skola-form' placeholder='17:00-21:00...'/><br />
                            <button className='form-control skola-button' onClick={edit}>Saglabāt</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditTeacher