import React from 'react'
import { signInWithPopup } from 'firebase/auth'
import { auth, provider } from '../services/firebase.config'


function Signup() {
    const login = ()=>{
        signInWithPopup(auth, provider);
    }

    return (
      <div className="login-center">
        <div>
            <h1>Pievienojies Ernesta Skolas sistēmai, izmantojot Google kontu.</h1>
            <br />
            <div><button className="skola-button" onClick={login}>Log in with Google</button></div>
        </div>
      </div>
    )
}

export default Signup
