import { addDoc, collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore';
import React from 'react'
import { db } from '../services/firebase.config';

function ResetTickets() {
    const reset = async()=>{
        console.log("starting reset...")

        let q = query(collection(db, "tickets"));
        let snap = await getDocs(q);
        let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));

        for(let ticket of data){
            await deleteDoc(doc(db, "tickets", ticket.id));
        }

        console.log("previous tickets deleted...")

        q = query(collection(db, "students"));
        snap = await getDocs(q);
        data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));

        for(let item of data){
            console.log(`Student: ${item.name} (${item.short}, ${item.long})`)
            for(let i=0; i<item.short; i++){
                await addDoc(collection(db, "tickets"), {email: item.id, type: "30 min"})
                console.log("short added")
            }
            for(let j=0; j<item.long; j++){
                await addDoc(collection(db, "tickets"), {email: item.id, type: "60 min"})
                console.log("long added")
            }
        }

        document.getElementById("close-complete").click();
    }

    return (
        <>
            <button type="button" className="skola-button" style={{marginTop: "10px"}} data-bs-toggle="modal" data-bs-target="#reset">
                Atjaunot biļetes
            </button>
            <div className="modal fade" id="reset" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: "transparent"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{background: "transparent"}}>
                    <div className="modal-content skola-popup">
                        <div className="modal-header" style={{background: "transparent"}}>
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Esi pārliecināts?</h1>
                            <button id="close-complete" type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}}>x</button>
                        </div>
                        <div className="modal-body" style={{background: "transparent"}}>
                            <button className='form-control btn btn-danger' onClick={reset}>Atjaunot</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetTickets
