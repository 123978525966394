import './App.css';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth, db} from './services/firebase.config';
import Signup from './components/Signup';
import Student from './components/Student';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import Teacher from './components/Teacher';
import Admin from './components/Admin';

function App() {
  const [user] = useAuthState(auth);
  const [role, setRole] = useState("");
  const [person, setPerson] = useState(null);

  useEffect(()=>{
    if (user == null) {
      setRole("");
      setPerson(null);
      return;
    }
    
    const getData = async () =>{
      console.log("app");

      const data = await getDoc(doc(db, "students", user.email));
      if(data.exists()){
        setRole("Student");
        setPerson({...data.data(), id:data.id});
        return;
      }

      const teacherData = await getDoc(doc(db, "teachers", user.email));
      if(teacherData.exists()){
        setRole("Teacher");
        setPerson({...teacherData.data(), id:teacherData.id});
        return;
      }

      const adminData = await getDoc(doc(db, "admins", user.email));
      if(adminData.exists()){
        setRole("Admin");
        setPerson({...adminData.data(), id:adminData.id});
        return;
      }
    }
    getData();
  }, [user])

  if(user){
    if(role === "Student"){
      return <Student person={person}/>;
    }
    if(role === "Teacher"){
      return <Teacher person={person}/>;
    }
    if(role === "Admin"){
      return <Admin person={person}/>;
    }
    return (<>
      <div className="login-center">
        <div>
          <h1>Piedod, tev nav piejas pie Ernesta Skolas platformai.</h1>
          <br />
          <div>Iespējams tu vēl neesi reģistrējies vai arī tev ir beidzies abonaments.</div>
          <div>Ja tā nav un tev vajadzētu būt pieejai, tad raksti uz ernestametode@gmail.com</div>
          <br />
          <button className='skola-button' onClick={()=>signOut(auth)}>Atslēgties</button>
        </div>
      </div>
      </>)
  }

  return <Signup />
}

export default App;
