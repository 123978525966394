import { addDoc, collection, deleteDoc, doc, Timestamp } from 'firebase/firestore'
import React, { useState } from 'react'
import { db } from '../services/firebase.config'
import { updateDoc } from 'firebase/firestore';


function Complete({change, setChange, o}) {
    const [report, setReport] = useState("");
    
    const complete = async () => {
        console.log("complete");
        await addDoc(collection(db, "past"), {...o, report: report})
        console.log(o.date.toMillis() + 7 * 24 * 60 * 60 * 1000)
        let newDate = o.date.toMillis() + 7 * 24 * 60 * 60 * 1000;
        if(o.type === "60 min (katru nedēļu)"){
            console.log("regular")
            await updateDoc(doc(db, "lessons", o.id), {date: Timestamp.fromDate(new Date(newDate)), desc: ""})
        }
        else{
            console.log("unregular")
            await deleteDoc(doc(db, "lessons", o.id))
        }
        setChange(change + 1);
        setReport("");
        document.getElementById("close-complete").click();
    }

    return (
        <>
            <div className="modal fade" id="complete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: "transparent"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{background: "transparent"}}>
                    <div className="modal-content skola-popup">
                        <div className="modal-header" style={{background: "transparent"}}>
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Ko jūs darījāt nodarbībā? Piezīmes?</h1>
                            <button id="close-complete" type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}}>x</button>
                        </div>
                        <div className="modal-body" style={{background: "transparent"}}>
                            <input onChange={(e)=>setReport(e.target.value)}  value={report} type='text' className='form-control skola-form' placeholder='Mēs šajā nodarbībā...'/><br />
                            <button className='form-control skola-button' onClick={complete}>Saglabāt un ielikt vēsturē</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Complete