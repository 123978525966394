import React, { useState, useEffect } from 'react'
import { auth, db } from '../services/firebase.config';
import { getDocs, collection, query, where, addDoc, Timestamp, deleteDoc, doc } from 'firebase/firestore';
import FirebaseDateComponent from './FirebaseDateComponent';
import { useAuthState } from 'react-firebase-hooks/auth';

function Booking({change, setChange, person, tickets}) {
    const [user] = useAuthState(auth);
    const [selected, setSelected] = useState({tname: "Izvēlies skolotāju", email: "", link: ""});
    const [teachers, setTeachers] = useState(null);
    const [lessons, setLessons] = useState(null);
    const [success, setSuccess] = useState(false);
    const [noTicket, setNoTicket] = useState(false);

    //form
    const [date, setDate] = useState("");
    const [type, setType] = useState("");

    function getTicketId(ttype){
        for(let ticket of tickets){
            if (ticket.type === ttype){
                return ticket.id;
            }
        }
        return null;
    }
    
    const getData = async () => {
        let q = query(collection(db, "teachers"))
        console.log("booking - teachers");
        let snap = await getDocs(q);
        let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
        setTeachers(data);
    }

    useEffect(() => {
        if(selected.tname === "Izvēlies skolotāju")return;
        
        const getLessons = async () => {
            let q = query(collection(db, "lessons"), where("teacher", "==", selected.email))
            console.log("booking - lessons");
            let snap = await getDocs(q);
            let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setLessons(data);
        }
        getLessons();
    }, [selected])

    const book = async () => {
        let ticket = getTicketId(type);
        if (ticket === null) return;
        
        await deleteDoc(doc(db, "tickets", ticket));

        const docRef = await addDoc(collection(db, 'lessons'), {
            client: user.email,
            cname: person.name,
            created: Timestamp.now(),
            date: Timestamp.fromDate(new Date(date)),
            desc: "",
            link: selected.link,
            teacher: selected.email,
            tname: selected.tname,
            type: type,
        });
        if(docRef != null){
            setSuccess(true);
            setChange(change+1);
            setDate("");
            setType("");
        }
    }
    
    return (
        <>
            <button type="button" className="skola-button" data-bs-toggle="modal" data-bs-target="#pieteikties" onClick={getData}>
                Pieteikties nodarbībai
            </button>
            <div className="modal fade" id="pieteikties" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content skola-popup">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Pieteikšanās</h1>
                            <button type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}} onClick={()=>setSuccess(false)}>x</button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="dropdown">
                                    <button className="skola-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selected.tname}
                                    </button>
                                    <ul className="dropdown-menu">
                                        {teachers && teachers.map(({desc,friday,link,monday,name,saturday,sunday,thursday,tuesday,wednesday,id})=>(
                                            <button className="dropdown-item" key={id} onClick={()=>setSelected({tname: name, email: id, link: link, desc: desc})}>{name}</button>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <br />
                            <p><b>Skolotāja bio</b></p>
                            <div>{selected.desc}</div>
                            <br />
                            <p><b>Skolotāja darba laiks</b></p>
                            {selected.tname !== "Izvēlies skolotāju" ? (
                                <div>
                                    {teachers && teachers.map(({desc,friday,monday,name,saturday,sunday,thursday,tuesday,wednesday,id})=>(
                                        <div key={id}>{name === selected.tname && (
                                            <div>
                                                Pirmdiena: {monday} <br />
                                                Otrdiena: {tuesday} <br />
                                                Trešdiena: {wednesday} <br />
                                                Ceturtdiena: {thursday} <br />
                                                Piektdiena: {friday} <br />
                                                Sestdiena: {saturday} <br />
                                                Svētdiena: {sunday} <br />
                                            </div>
                                        )}</div>
                                    ))}
                                </div>
                            ):(
                                <>Izvēlies skolotāju...</>
                            )}

                            <br /><br />
                            <p><b>Aizņemtie laiki</b></p>
                            {selected.tname !== "Izvēlies skolotāju" ? (
                                <div>
                                    {lessons && lessons.map(({client,cname,created,date,desc,link,teacher,tname,type,id})=>(
                                        <div key={id}>
                                            <FirebaseDateComponent timestamp={date} /> - {type.split(" ")[0]} {type.split(" ")[1]}
                                        </div>
                                    ))}
                                </div>
                            ):(
                                <>Izvēlies skolotāju...</>
                            )}
                            
                            <br /><br />
                            <p><b>Pieteikt laiku</b></p>
                            {selected.tname !== "Izvēlies skolotāju" ? (
                                <div>
                                    <input className='form-control skola-form' type="datetime-local" value={date} onChange={(e)=>setDate(e.target.value)} /> <br />
                                    <select className='form-control skola-form' value={type} onChange={(e)=>{
                                        setType(e.target.value);
                                        if(e.target.value === "Izvēlies ilgumu...")return;
                                        if(getTicketId(e.target.value)==null){
                                            setNoTicket(true);
                                        }
                                        else{
                                            setNoTicket(false);
                                        }
                                    }}>
                                        <option>Izvēlies ilgumu...</option>
                                        <option>30 min</option>
                                        <option>60 min</option>
                                    </select><br />
                                    <button className='form-control skola-button' onClick={book}>Pieteikties</button><br />
                                    {success && <div style={{textAlign: "center", color: "green"}}>Pieteikums izveidots veiksmīgi!</div>}
                                    {noTicket && <div style={{textAlign: "center", color: "red"}}>Diemžēl jau esi iztērējis šī garuma nodarbības.</div>}
                                </div>
                            ):(
                                <>Izvēlies skolotāju...</>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Booking
