import { collection, getDocs, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../services/firebase.config'


function Test({o}) {
    const [answer, setAnswer] = useState("");
    const [tasks, setTasks] = useState([]);
    const [index, setIndex] = useState(-1);
    const [correct, setCorrect] = useState(0);

    const submit = ()=>{
        if(answer === tasks[index].answer)setCorrect(correct+1);
        setIndex(index+1)
        setAnswer("");
        // if test finished save the results for admin to accept
    }

    useEffect(()=>{
        const getData = async () => {
            if(!o)return;
            
            let q = query(collection(db, "tests", o.id, "tasks"))
            console.log("test - tasks");
            let snap = await getDocs(q);
            let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setTasks(data);
            setAnswer("");
            setIndex(-1);
            setCorrect(0);
        }
        getData();
    }, [o])

    return (
        <>
            <div className="modal fade" id="test" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: "transparent"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{background: "transparent"}}>
                    <div className="modal-content skola-popup">
                        <div className="modal-header" style={{background: "transparent"}}>
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{o && o.title}</h1>
                            <button id="close-test" type="button" className="skola-button" data-bs-dismiss="modal" style={{paddingTop: "7px"}}>x</button>
                        </div>
                        {index < 0 && <div className="modal-body" style={{background: "transparent"}}>
                            <p>{o && o.desc}</p>
                            <p style={{color: "red"}}>Esi uzmanīgs! Ja testu aizvērsi pildīšanas laikā, tad atbildes nesaglabāsies. Pieraksti katru atbildi sev, lai, ja nu gadījumā jāsāk no jauna, tad nav viss jāpārrēķina.</p>
                            <button className='form-control skola-button' onClick={()=>setIndex(index+1)}>Sākt!</button>
                        </div>}
                        {index >= 0 && index < tasks.length && <div className="modal-body" style={{background: "transparent"}}>
                            <p>{tasks[index].text}</p>
                            <p><img src={tasks[index].img} width="100%" style={{borderRadius: "10px"}} alt=""/></p>
                            <input onChange={(e)=>setAnswer(e.target.value)}  value={answer} type='text' className='form-control skola-form' placeholder='Mēs šajā nodarbībā...'/><br />
                            <button className='form-control skola-button' onClick={submit}>Atbildēt</button>
                        </div>}
                        {index >= tasks.length && <div className="modal-body" style={{background: "transparent"}}>
                            <p>Pareizās atbildes: {correct}/{tasks.length}</p>
                            <h1 style={correct/tasks.length*100 >= 50 ? {color: "green"} : {color: "red"}}>{Math.round(correct/tasks.length*100)}%</h1>
                            <br /><button className='form-control skola-button' onClick={()=>document.getElementById("close-test").click()}>Aizvērt</button>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Test