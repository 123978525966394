// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABdQ62SjmQDDpOgGNx7bqJwyYV29ZpWZg",
  authDomain: "ernesta-skola.firebaseapp.com",
  projectId: "ernesta-skola",
  storageBucket: "ernesta-skola.firebasestorage.app",
  messagingSenderId: "963669378963",
  appId: "1:963669378963:web:2a6ed0af9feecdea464a66",
  measurementId: "G-KMKRP1GYFW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const provider = new GoogleAuthProvider();
export const auth = getAuth();
