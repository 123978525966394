import React, { useEffect, useState } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { db } from '../services/firebase.config';
import FirebaseDateComponent from './FirebaseDateComponent';
import Booking from './Booking';
import Past from './Past';
import AddTopic from './AddTopic';
import Test from './Test';

function Student({person}) {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const signout = () => signOut(auth);
    const [lessons, setLessons] = useState([]);
    const [change, setChange] = useState(0);
    const [tickets, setTickets] = useState([]);
    const [points, setPoints] = useState(0);
    const [tests, setTests] = useState([]);
    const [selected, setSelected] = useState("");
    const [test, setTest] = useState(null);

    useEffect(()=>{
        const getData = async () => {
            let q = query(collection(db, "lessons"), where("client", "==", user.email), orderBy("date", "asc"))
            console.log("student - lessons");
            let snap = await getDocs(q);
            let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setLessons(data);

            q = query(collection(db, "tickets"), where("email", "==", user.email))
            console.log("student - tickets");
            snap = await getDocs(q);
            data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setTickets(data);
        }
        getData();
    }, [user, change])

    useEffect(()=>{
        const getData = async () => {
            console.log("student - points");
            let snap = await getDoc(doc(db, "points", user.email));
            if(!snap.exists()){
                snap = await setDoc(doc(db, "points", user.email), {points: 0});
            }
            else{
                let data = snap.data().points;
                setPoints(data);
            }

            let q = query(collection(db, "tests"))
            console.log("student - tests");
            snap = await getDocs(q);
            let data = snap.docs.map((doc)=>({...doc.data(), id: doc.id}));
            setTests(data);
        }
        getData();
    }, [user])
    
    return (
      <div className="container text-center mt-5">
        <h1>Skolēna panelis</h1>
        <p>Pievienojies kā: {person.name} ({person.id})</p>
        <p>Gudrības punkti: {points}</p>
        <p>
            <b>Šomēnes tu vari pieteikt vēl:</b><br />
            {tickets && tickets.map(({email, type, id})=>(
                <span key={id}>1 x {type} nodarbība <br /></span>
            ))}
        </p>
        <div className='skola-panel'>
            <h3>Pieteiktās nodarbības:</h3>
            <div className="skola-cards">
                {lessons && lessons.map(({client,cname,created,date,desc,link,teacher,tname,type,id})=>(
                    <div key={id}>
                        <h5><FirebaseDateComponent timestamp={date} /> - {type}</h5>
                        <b>Datums:</b> {date.toDate().toLocaleDateString('lv-LV')} <br />
                        <b>Skolotājs:</b> {tname} ({teacher}) <br />
                        <b>Tēma:</b> {desc!=="" ? desc : <span style={{color: "red"}}><b>NAV PIEVIENOTA TĒMA</b></span>} <br/>
                        <b>Links:</b> <a href={link}>{link}</a>
                        {desc === "" && <><br /><button type="button" onClick={(e)=>setSelected(id)} className="skola-button-secondary" style={{marginTop: "10px"}} data-bs-toggle="modal" data-bs-target="#add">
                            Pievienot tēmu!
                        </button></>}
                    </div>
                ))}
            </div>
            <span style={{marginRight: "10px"}}><Past /></span>
            <Booking change={change} setChange={setChange} person={person} tickets={tickets}/>
            <AddTopic uid={selected} change={change} setChange={setChange}/>
        </div>
        <div className='skola-panel'>
            <h3>Darāmie testi:</h3>
            <div className="skola-cards">
                {tests && tests.map(({title, desc, type, id})=>(
                    <div className='d-flex justify-content-between align-items-center'>
                        <span><h5>{title}</h5>{desc}</span>
                        <span>
                            <button type="button" className="skola-button-secondary" data-bs-toggle="modal" data-bs-target="#test" onClick={(e)=>setTest({title, desc, type, id})}>
                                Sākt pildīt!
                            </button>
                        </span>
                    </div>
                ))}
            </div>
        </div>
        <Test o={test}/>
        <div className='mb-5'><button className="skola-button" onClick={signout}>Log out</button></div>
      </div>
    )
}

export default Student
