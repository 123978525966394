import 'firebase/firestore';

function formatDateTime(firebaseTimestamp) {
  if (!firebaseTimestamp) return '';

  // Convert Firebase timestamp to a JavaScript Date object
  const date = firebaseTimestamp.toDate();

  // Format the date as DayName, DD.MM.YYYY in Latvian
  let formattedDate = date.toLocaleDateString('lv-LV', {
    weekday: 'long', // Full day name
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  // Capitalize the first letter of the day name
  formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  // Format the time as HH:MM
  const formattedTime = date.toLocaleTimeString('lv-LV', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // Use 24-hour format
  });

  let seperate = formattedDate.split(",")

  return `${seperate[0]} ${formattedTime}`;
}

const FirebaseDateTimeComponent = ({ timestamp }) => {
  return formatDateTime(timestamp);
};

export default FirebaseDateTimeComponent;
